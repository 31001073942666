import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "",
        name: 'home',
        component: () => import("../views/Home.vue")
      },
      {
        path: "/role",
        name: "role",
        component: () => import("../views/sys/Role.vue")
      },
      {
        path: "/manager",
        name: "manager",
        component: () => import("../views/sys/Manager.vue")
      },
      {
        path: "/navigation",
        name: "navigation",
        component: () => import("../views/sys/Navigation.vue")
      },
      {
        path: "/siteconfig",
        name: "siteconfig",
        component: () => import("../views/sys/SiteConfig.vue")
      },
      {
        path: "/banner",
        name: "banner",
        component: () => import("../views/sys/Banner.vue")
      },
      {
        path: "/articleInfo",
        name: "articleInfo",
        component: () => import("../views/sys/ArticleInfo.vue")
      },
      {
        path: "/articleStatic/:id",
        name: "articleStatic",
        component: () => import("../views/sys/ArticleStatic.vue"),
      },
      {
        path: "/data",
        name: "data",
        component: () => import("../views/sys/Data.vue")
      },
      {
        path: "/category",
        name: "category",
        component: () => import("../views/sys/Category.vue")
      },
      {
        path: "/userlist",
        name: "userlist",
        component: () => import("../views/user/Userlist.vue")
      },
      {
        path: "/collect_year",
        name: "collect_year",
        component: () => import("../views/collect/Year.vue")
      },
      {
        path: "/shopGoods",
        name: "shopGoods",
        component: () => import("../views/mall/ShopGoods.vue")
      },
      {
        path: "/order",
        name: "order",
        component: () => import("../views/mall/Order.vue")
      },
      {
        path: "/comment",
        name: "comment",
        component: () => import("../views/mall/Comment.vue")
      },
      {
        path: "/scoreRecord",
        name: "scoreRecord",
        component: () => import("../views/mall/ScoreRecord.vue")
      },
      {
        path: "/growthRecord",
        name: "growthRecord",
        component: () => import("../views/mall/GrowthRecord.vue")
      },
      {
        path: "/articleCate",
        name: "articleCate",
        component: () => import("../views/mall/ArticleCate.vue")
      },
      {
        path: "/articleList",
        name: "articleList",
        component: () => import("../views/mall/ArticleList.vue")
      },
      {
        path: "/scoreGoods",
        name: "scoreGoods",
        component: () => import("../views/mall/ScoreGoods.vue")
      },
      {
        path: "/scoreOrder",
        name: "scoreOrder",
        component: () => import("../views/mall/ScoreOrder.vue")
      },
      {
        path: "/shopExpress",
        name: "shopExpress",
        component: () => import("../views/mall/ShopExpress.vue")
      },
      {
        path: "/scoreGoodsCate",
        name: "scoreGoodsCate",
        component: () => import("../views/mall/ScoreGoodsCate.vue")
      },
      {
        path: "/coupon",
        name: "coupon",
        component: () => import("../views/this/Coupon.vue")
      },
      {
        path: "/couponRecord",
        name: "couponRecord",
        component: () => import("../views/this/CouponRecord.vue")
      },
      {
        path: "/finBonus",
        name: "finBonus",
        component: () => import("../views/other/FinBonus.vue")
      },
      {
        path: "/finDraw",
        name: "finDraw",
        component: () => import("../views/other/FinDraw.vue")
      },
      {
        path: "/rotationRecord",
        name: "rotationRecord",
        component: () => import("../views/other/RotationRecord.vue")
      },
      {
        path: "/changeRecord",
        name: "changeRecord",
        component: () => import("../views/other/ChangeRecord.vue")
      },
      {
        path: "/memberUpgrade",
        name: "memberUpgrade",
        component: () => import("../views/other/MemberUpgrade.vue")
      },
      {
        path: "/recommend",
        name: "recommend",
        component: () => import("../views/other/Recommend.vue")
      },
      {
        path: "/vip",
        name: "vip",
        component: () => import("../views/user/Vip.vue")
      },
      {
        path: "/agent",
        name: "agent",
        component: () => import("../views/user/Agent.vue")
      },
      {
        path: "/serviceUser",
        name: "serviceUser",
        component: () => import("../views/user/ServiceUser.vue")
      },
      {
        path: "/goodsCate",
        name: "goodsCate",
        component: () => import("../views/sys/GoodsCate.vue")
      },

    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue")
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
